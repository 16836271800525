import React, { useContext } from "react";

import { InterractiveSection } from "@chweb/commonui";
import InterviewQuestion from "../components/interview-question";
import {
  PROGRESS_LOCATION as CarouselProgress,
  TRANSITION as CarouselTransition,
  THEME as CarouselTheme,
  Carousel
} from "@chweb/commonui";

import {
  InterviewQuestion as Question
} from "../libs/interview-question";

import WithMargins from "../components/with-margins";

import { LocaleContext, getLocalizedValue } from "../components/locale-context";
import { getInterview, InterviewData } from "../queries/get-interview";

function InterviewSection () {
  const { locale } = useContext(LocaleContext);

  const templatedQuestions = (questions: InterviewData[]) =>
    questions.map((question, index) => {
      if (!question.question || !question.answer) {
        return <></>;
      }
      const qObject = new Question(
        question.question,
        question.answer,
        question.quote,
        question.quoteAuthor);

      if (question.link !== undefined && question.link.label !== undefined && question.link.url !== undefined) {
        qObject.setLink(question.link.label, question.link.url);
      }
      return (
        <WithMargins margin="2%" key = { 'question' + index }>
          <InterviewQuestion question = { qObject } />
        </WithMargins>
      );
    });

  const interview = getInterview();
  const localInterview = getLocalizedValue(interview, locale);

  return (
    <InterractiveSection title = { localInterview.labels.sectionInterviewTitle }>
      <div className = "w3-padding-16">
        <Carousel
          progressLocation = { CarouselProgress.BOTTOM }
          transition = { CarouselTransition.SLIDE }
          timeOutSecs = { 20 }
          theme = { CarouselTheme.BROWN }
          sidePadding
          disableMargins
        >
          { templatedQuestions(localInterview.questions) }
        </Carousel>
      </div>
    </InterractiveSection>
  );
}

export default InterviewSection;
