import React, { ReactElement } from 'react';

import Layout from '../components/layout';
import Banner from '../components/banner';
import SectionDomaine from '../components/section-domain';
import SectionWines from '../components/section-wines';
import Media from '../components/section-media';
import Interview from '../components/section-interview';
import Recipes from '../components/section-recipes';
import ExtraContentLinks from '../components/domain-extra-links';
import Announcement from '../components/section-announcement';

import { defaultLocale, getPreferredLocale } from "@chweb/commonui";
import { localeFromUrl } from '../libs/locale';
import { PageSEO } from '../libs/seo';
import { LocationInfo } from '../libs/location';

function getSEO (location: LocationInfo, locale: string): PageSEO {
  // handling special case for english when it's specified as search args
  const url = locale === 'en' && !location.search ? `${location.href}?${locale}` : location.href;
  const seo = new PageSEO(url);
  return seo;
}

interface IndexPageProps {
  pageContext: { slug: string, locale: string},
  location: LocationInfo
}

export default function IndexPage ({ pageContext, location }: IndexPageProps): ReactElement {
  let locale = localeFromUrl(location);

  // url locale is always a priority in case it's "forced"
  // e.g. user explicitly wants EN this url will skip autodetection logic
  if (!locale) {
    const defaultL = defaultLocale();

    locale = pageContext.locale;
    if (locale === defaultL) {
      locale = getPreferredLocale();
    }
  }

  return (
    <Layout noMenuOffset locale={locale} location={ location } seo = { getSEO(location, locale) }>
      <Banner />
      <SectionDomaine />
      <Announcement />
      <ExtraContentLinks />
      <Media />
      <SectionWines />
      <Interview />
      <Recipes />
    </Layout>
  );
}
