import {
  useStaticQuery,
  graphql
} from 'gatsby';

import { IGatsbyImageData } from 'gatsby-plugin-image';

import parseTOC, { LinkAndLabel } from '../libs/markdown-toc';
import { PageInfoData } from './fragment-page-info';

export interface DomaineIntroSlide {
    image: IGatsbyImageData,
    description: string
}

export interface DomaineIntro {
  title: string,
  text: string,
  slides: DomaineIntroSlide[]
}

export interface DomaineIntroWithMenu {
  menu?: LinkAndLabel[],
  intro?: DomaineIntro
}

interface MenuNode {
  tableOfContents: string,
  fields:  {
    slug: string
  }
}

interface DomaineIntroLocalNode {
  html: string,
  fields: {
    slug: string
  }
} 

interface DomaineIntroNode {
  pageId: PageInfoData
  slides: {
      src: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        } 
      },
      description: [{
        locale: string,
        text: string
      }]
    }[]
}

export const getDomaineIntro = () => {
  const {
    menu,
    intro,
    local
  } = useStaticQuery(
    graphql`{
        menu: allMarkdownRemark(filter: {fields: {slug: {regex: "/domaine\\.[a-z]{2}/"}}}) {
          nodes {
            tableOfContents
            fields {
              slug
            }
          }
        }
        intro: markdownRemark(fields: {slug: {eq: "/domaine-intro/"}}) {
          frontmatter {
            pageId {
              ...PageInfo
            }
            slides {
              src {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 800)
                }
              }
              description {
                locale
                text
              }
            }
          }
        }
        local: allMarkdownRemark(filter: {fields: {slug: {regex: "/domaine-intro\\.[a-z]{2}/"}}}) {
          nodes {
            html
            fields {
              slug
            }
          }
        }
      }`
  );

  const byLocale: { [key: string]: DomaineIntroWithMenu } = {};
  const ensureLocaleExists = (locale: string) => {
    if (!(local in byLocale)) {
      byLocale[locale] = {};
    }
  };

  const getLocaleCode = (slug: string) => {
    const match = slug.match(/\.([a-z]{2})\/$/);
    if (Array.isArray(match)) {
      return match[1];
    }
  };

  const menuNodes: MenuNode[] = menu.nodes;
  menuNodes.forEach(m => {
    const locale = getLocaleCode(m.fields.slug) as string;
    ensureLocaleExists(locale);
    byLocale[locale].menu = parseTOC(m.fields.slug, m.tableOfContents);
  });

  const introNode: DomaineIntroNode = intro.frontmatter;
  const localNodes: DomaineIntroLocalNode[] = local.nodes;
  localNodes.forEach(i => {
    const locale = getLocaleCode(i.fields.slug) as string;
    ensureLocaleExists(locale);
    const localTitle = introNode.pageId.pageTitle.find(t => t.locale === locale);
    const domaineIntro: DomaineIntro = {
      title: localTitle ? localTitle.text : '',
      text: i.html,
      slides: introNode.slides.map(s => {
        const localDescription = s.description.find(d => d.locale === locale);
        return {
          image: s.src.childImageSharp.gatsbyImageData,
          description: localDescription ? localDescription.text : '' 
        };
      })
    };
    byLocale[locale].intro = domaineIntro;
  });

  return byLocale;
}
