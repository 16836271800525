import { useStaticQuery, graphql } from 'gatsby';

interface PhilosophyData {
  md: {
    nodes: {
      frontmatter: {
        tooltip: string
      },
      html: string,
      fields: {
        slug: string
      }
    }[]
  }
}

export const getPhilosophy = () => {
  const { md }: PhilosophyData = useStaticQuery(
    graphql`{
      md: allMarkdownRemark(filter: {fields: {slug: {regex: "/philosophy\\.[a-z]{2}/"}}}) {
          nodes {
            frontmatter {
              tooltip
            }
            html
            fields {
              slug
            }
          }
        }
      }`
  );

  const phLocalized: { [key: string]: { html: string, tooltip: string } } = {};

  md.nodes.reduce((acc, n) => {
    const matches = n.fields.slug.match(/\.([a-z]{2})\/$/);
    if (!matches)
      return acc;

    const localeCode = matches[1];
    acc[localeCode] = {
      html: n.html,
      tooltip: n.frontmatter.tooltip
    };
    return acc;
  }, phLocalized);

  return phLocalized;
}
