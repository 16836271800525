import React, { ReactElement, useContext } from "react";

import {
  InterractiveSection,
  PROGRESS_LOCATION as CarouselProgress,
  TRANSITION as CarouselTransition,
  Carousel,
  THEME as CarouselTheme
} from "@chweb/commonui";

import ColumnsSection from "../components/columns-section";
import WithMargins from "../components/with-margins";
import RecipeSection from "../components/one-recipe-section";

import { LinkData } from "@chweb/commonlib" 
import { Time } from "../libs/time";

import { RecipeStats } from "../libs/recipe-stats";

import { localizeUrl } from "../libs/locale";
import { LocaleContext, getLocalizedValue } from "../components/locale-context";
import { getRecipesSection, RecipeData } from "../queries/get-recipes-section";

const allRecipesLink = "/recipes/";

interface RecipeColumnProps {
  recipe: RecipeData 
}

function RecipeColumn ({ recipe }: RecipeColumnProps): ReactElement {
  return (
    <WithMargins margin="5%">
      <RecipeSection
        verticalLayout
        title = { recipe.title }
        titleimage = { recipe.titleimage }
        wineIdMatches = { recipe.pairing }
        recipeStats = {
          new RecipeStats(
            recipe.info.prepmins ? new Time(0, recipe.info.prepmins as number) : null,
            recipe.info.mins ? new Time(0, recipe.info.mins) : null,
            recipe.info.servings)
        }
        recipeLink = { recipe.url }
        excerpt = { recipe.excerpt }
      />
    </WithMargins>
  );
}

function generateRecipesSlides (recipes: RecipeData[], perSlide: number): ReactElement[] {
  const slideCount = Math.floor(recipes.length / perSlide);

  const slides = [];
  for (let i = 0; i < slideCount; ++i) {
    const rIdx = i * perSlide;
    const columns = [];
    for (let c = 0; c < perSlide; ++c) {
      columns.push(<RecipeColumn recipe = { recipes[rIdx + c] } />);
    }
    slides.push(
      <ColumnsSection key = { i.toString() } cols = { columns }/>
    );
  }
  return slides;
}

const linkData = (label: string, locale: string) => new LinkData(localizeUrl(allRecipesLink, locale), label);

function RecipesSection () : ReactElement {
  const recipesSectionData = getRecipesSection();
  const { locale } = useContext(LocaleContext);

  const localizedData = getLocalizedValue(recipesSectionData, locale);
  const SMALL_SCREEN_RECIPES_PER_SLIDE = 1;
  const DEFAULT_SCREEN_RECIPES_PER_SLIDE = 2;
  const CAROUSEL_TIMEOUT_SECS = 20;
  return (
    <InterractiveSection
      title = { localizedData.title as string }
      bottomLink = { linkData(localizedData.sectionRecipesAllLink as string, locale) }
    >
      <div>
        <div className = "w3-padding-24 w3-hide-small">
          <Carousel
            progressLocation = { CarouselProgress.BOTTOM }
            transition = { CarouselTransition.SLIDE }
            timeOutSecs = { CAROUSEL_TIMEOUT_SECS }
            theme = { CarouselTheme.BROWN }
            sidePadding
            disableMargins
          >
            { generateRecipesSlides(localizedData.recipes as RecipeData[], DEFAULT_SCREEN_RECIPES_PER_SLIDE) }
          </Carousel>
        </div>
        <div className = "w3-padding-24 w3-hide-medium w3-hide-large">
          <Carousel
            progressLocation = { CarouselProgress.BOTTOM }
            transition = { CarouselTransition.SLIDE }
            timeOutSecs = { CAROUSEL_TIMEOUT_SECS }
            theme = { CarouselTheme.BROWN }
            sidePadding
            disableMargins
          >
            { generateRecipesSlides(localizedData.recipes as RecipeData[], SMALL_SCREEN_RECIPES_PER_SLIDE) }
          </Carousel>
        </div>
      </div>
    </InterractiveSection>
  );
}

export default RecipesSection;
