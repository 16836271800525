import { useStaticQuery, graphql } from 'gatsby';

import { localizeUrl } from '../libs/locale';
import { AllLocalesLabels } from './fragment-all-locales-labels';

interface TextWithLocale {
  locale: string,
  text: string
}

interface InterviewDataRaw {
  labels: {
    nodes: AllLocalesLabels[]
  },
  md: {
    childMarkdownRemark: {
      frontmatter: {
        interview: {
          question: TextWithLocale[],
          answer: TextWithLocale[],
          quote: TextWithLocale[],
          quoteAuthor: TextWithLocale[],
          quoteLink: {
            url: string,
            localizeUrl: string,
            locales: TextWithLocale []
          }
        }[]
      }
    }
  }
}

export interface InterviewData {
  question: string | undefined,
  answer: string | undefined,
  quote: string | undefined,
  quoteAuthor: string | undefined,
  link: {
    url: string | undefined 
    label: string | undefined 
  } | undefined 
}

export const getInterview = () => {
  const { md, labels }: InterviewDataRaw = useStaticQuery(
    graphql`{
      labels: allLabelsTranslatedYaml(filter: {name: {in: [
          "sectionInterviewTitle",
      ]}}) {
        nodes {
          ...AllLocalesLabels
        }
      }

      md: file (relativePath: {eq: "interview/interview.md"}) {
        childMarkdownRemark {
          frontmatter {
            interview {
              question {
                locale
                text
              }
              answer {
                locale
                text
              }
              quote {
                locale
                text
              }
              quoteAuthor {
                locale
                text
              }
              quoteLink {
                url
                localizeUrl
                locales {
                  locale
                  text
                }
              }
            }
          }
        }
      }
    }`
  );

  const questions = md.childMarkdownRemark.frontmatter.interview;

  const byLocale: { [key: string]: { labels: {[key: string]: string}, questions: InterviewData[]}}= {};
  const ensureLocaleExists = (locale: string) => {
    if (!byLocale.hasOwnProperty(locale)) {
      byLocale[locale] = { labels: {}, questions: [] };
    }
  };

  labels.nodes.forEach(l => {
    const label = l.name;
    const locales = l.locales;
    Object.keys(locales).forEach(locale => {
      ensureLocaleExists(locale);
      byLocale[locale].labels[label] = locales[locale];
    });
  });

  let locales: string[] = [];
  if (questions.length) {
    locales = questions[0].question.map(q => q.locale);
  }

  const localeTextFieldOptional = (a: TextWithLocale[], locale: string) => {
    if (Array.isArray(a)) {
      const match = a.find(v => v.locale === locale);
      if (match) {
        return match.text;
      }
    }
    return undefined;
  };

  locales.forEach(l => {
    byLocale[l].questions = questions.map(q => {
      const { question, answer, quote, quoteAuthor, quoteLink } = q;
      return {
        question: localeTextFieldOptional(question, l),
        answer: localeTextFieldOptional(answer, l),
        quote: localeTextFieldOptional(quote, l),
        quoteAuthor: localeTextFieldOptional (quoteAuthor, l),
        link: !quoteLink ? undefined : {
          url: quoteLink.localizeUrl ? localizeUrl(quoteLink.url, l) : quoteLink.url,
          label: localeTextFieldOptional(quoteLink.locales, l)
        }
      };
    });
  });

  return byLocale;
}
