import { localizeUrl } from './locale';

export type LinkAndLabel = {
  link: string,
  label: string
};

// parses out table of contents from HTML
export default function parseTOC (slug: string, raw: string): LinkAndLabel[] {
  const matcher = new RegExp('"(/[a-z0-9]+)?(' + slug + '#[\\S/-]+)">([^<]+)</', 'g');

  const results: LinkAndLabel[] = [];
  let matches = matcher.exec(raw);
  while (matches) {
    switch (matches.length) {
      case 3: // matches without prefix
        results.push({ link: localizeUrl(matches[1]), label: matches[2] });
        break;
      case 4: // matches cases with prefix
        results.push({ link: localizeUrl(matches[2]), label: matches[3] });
        break;
      default:
        console.warn('Failed to parse TOC links');
    }
    matches = matcher.exec(raw);
  }
  return results;
}
