import { LinkData } from "@chweb/commonlib";

class InterviewQuestion {
    public question: string;
    public answer: string;
    public quote: string | null;
    public quoteBy: string | null;
    public link: LinkData | null;

    constructor (question: string, answer: string, quote: string | null = null, quoteBy: string | null = null) {
      this.question = question;
      this.answer = answer;
      this.quote = quote;
      this.quoteBy = quoteBy;
      this.link = null;
    }

    public parseLink (pipeDelimited: string): void {
      const parts = pipeDelimited.split('|');
      if (parts.length !== 2) {
        console.error('Failed to parse interview link: ', pipeDelimited);
        console.error('Expected format: Text|Link');
      }
      this.setLink(parts[0], parts[1]);
    }

    public setLink (linkText: string, link: string): void {
      this.link = new LinkData(link, linkText);
    }
}

export { InterviewQuestion };
