import { useStaticQuery, graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

interface BannerSlidesData {
  slides: {
    frontmatter: {
      slides: {
        file: {
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData
          }
        }
      } []
    }
  }
}

export const getSlides = () => {
  const { slides }: BannerSlidesData = useStaticQuery(
    graphql`{
      slides: markdownRemark (fields:{slug:{eq: "/banner/"}})
      {
        frontmatter {
          slides {
            file {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1000)
              }
            }
          }
        }
      }
    }`
  );

  return slides.frontmatter.slides.map(s => s.file.childImageSharp.gatsbyImageData);
}
