import React from "react";
import { GatsbyImage as Img, IGatsbyImageData } from "gatsby-plugin-image";

import {
  PROGRESS_LOCATION as CarouselProgress,
  TRANSITION as CarouselTransition,
  Carousel,
  THEME as CarouselTheme
} from "@chweb/commonui"

import { LocaleContext, getLocalizedValue } from "./locale-context";
import { LogoLarge } from "./logo";

import { getPhilosophy } from "../queries/get-philosophy";
import { getSlides } from "../queries/get-banner-slides";

import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const bannerTemplate = (image: IGatsbyImageData, i: number, title ='') => (
  <Img
    image = { image }
    className = "w3-image w3-animate-opacity"
    key = { 'banner' + i.toString() }
    style = {{ width: `1000px` }}
    alt = { title }
  />
);

function unwrapText (text: string): string {
  const matches = text.match(/^<[^>]+>(.+)<\/[^>]+>$/);
  return (matches && matches.length === 2) ? matches[1] : text;
}

export default function Header () {
  const localized = getPhilosophy();
  const slides = getSlides();
  return (
    <LocaleContext.Consumer>
      {
        ({ locale }) => {
          const localValue = getLocalizedValue(localized, locale);
          const philosophy = unwrapText(localValue.html);
          const philosophyTooltip = localValue.tooltip;
          return (
            <header className = "w3-display-container">
              <h1 className = "w3-hide">Domaine du Chalet Pouilly</h1>
              <div>
                <Carousel
                  disableArrows
                  disableMargins
                  progressLocation = { CarouselProgress.BOTTOM_INSIDE }
                  transition = { CarouselTransition.FADE}
                  timeOutSecs = { 20 }
                  theme = { CarouselTheme.WHITE }
                >
                  { slides.map((image, i) => bannerTemplate(image, i)) }
                </Carousel>
              </div>
              <div className = "w3-display-topleft w3-border-bottom w3-border-white w3-opacity"
                style = {{ height: `90px`, width: `94%`, marginLeft: `3%`, marginRight: `3%` }}></div>
              <div className = "w3-display-topleft w3-margin-left w3-margin-right" style = {{ marginTop: '250px' }}>
                <LogoLarge />
              </div>
              <div
                className ="w3-display-bottomright w3-card-4 w3-margin-right w3-white w3-opacity-min w3-hide-small w3-hide-medium"
                style = {{ marginBottom: `100px`, maxWidth: `400px` }}
                title = { philosophyTooltip }>
                <p className = "w3-container">
                  <FontAwesomeIcon icon = { faQuoteRight } className = "w3-large cpcolor-text-brown" />
                  <br/>{ philosophy }
                </p>
              </div>
            </header>
          );
        }
      }
    </LocaleContext.Consumer>
  );
}
