import { useStaticQuery, graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { reduceLocales } from '../libs/locale';
import { TileItem } from '../libs/tile-data';
import { PageTitleData } from './fragment-page-info';

interface MenuTileData {
  pageId: {
    id: string,
    baseUrl: string,
    seoImage: string,
    pageTitle: PageTitleData
  },
  tileImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

export const getExtraLinkTiles = (locale: string): TileItem[] => {
  const { md } = useStaticQuery(
    graphql`{
      md: markdownRemark(fields: {slug: {eq: "/menu-domaine/"}}) {
        frontmatter {
          menuTiles {
            pageId {
              ...PageInfo
            }
            tileImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
    }`
  );

  const tiles: MenuTileData[] = reduceLocales(md.frontmatter.menuTiles, locale);
  return tiles.map(t => {
    const pageInfo = t.pageId;
    return new TileItem(pageInfo.pageTitle.text,
      t.tileImage.childImageSharp.gatsbyImageData, pageInfo.pageTitle.tooltip, pageInfo.baseUrl);
  });
}
