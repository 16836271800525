import React from 'react';
import { Link } from 'gatsby';

import { GatsbyImage as Img, IGatsbyImageData } from 'gatsby-plugin-image';

interface WineCardProps {
  imgAlt: string,
  img: IGatsbyImageData,
  link: string
}

export default class WineCard extends React.Component<WineCardProps> {
  render () {
    return (
      <Link
        to = { this.props.link }
        title = { this.props.imgAlt }
      >
        <div className = "w3-center w3-padding-16 w3-hover-white w3-hover-opacity cphover-slow w3-border w3-border-white cpcolor-hover-border-brown" >
          <Img image={this.props.img} alt={this.props.imgAlt} style={{ margin: 'auto' }}/>
        </div>
      </Link>
    );
  }
}
