import React, { useContext, ReactElement } from "react";

import { Link } from "gatsby";

import { MenuData, MenuItem } from "../libs/menu-data";
import { ThemeContext, ThemeFactory } from "@chweb/commonui";

function itemsDivs (items: MenuItem[], active: number, onClick: PageSectionOnClick | undefined, basicStyle: string, activeStyle: string) {
  return items.map((i, index) => {
    if (typeof onClick === 'function') {
      const className = index === active
        ? `w3-bar-item w3-button ${activeStyle}`
        : `w3-bar-item w3-button ${basicStyle}`;
      return <div
        className = { className }
        key = {index.toString()}
        onClick = { () => onClick(index) }
        title = { i.getTooltip() }
      >
        {i.getText()}
      </div>;
    } else {
      return <Link
        to = { i.getLink() }
        className = "w3-bar-item w3-button cpcolor-hover-brown"
        key = {index.toString()}
      >
        {i.getText()}
      </Link>;
    }
  });
}

type PageSectionOnClick = (sectionId: number) => void;

type PageSectionMenuImplProps = {
  menuData: MenuData;
  active: number;
  onClick?: PageSectionOnClick;
  orientation?: PageSectionMenuOrientation;
  className?: string;
  basicStyle?: string;
  activeStyle?: string;
};

function PageSectionMenuImpl ({ menuData, active, onClick, orientation, className, basicStyle, activeStyle }: PageSectionMenuImplProps): ReactElement {
  const theme = useContext(ThemeContext) || ThemeFactory.make();

  basicStyle = `${basicStyle} ${theme.link()}`;
  activeStyle = `${activeStyle} ${theme.activeLink()}`;

  const orientationStyle = orientation === PageSectionMenuOrientation.VERTICAL ? 'w3-bar-block' : 'w3-bar';
  return (
    <div className = { `${orientationStyle} w3-margin-left w3-large ${className}` } style={{ fontWeight: 200 }} >
      { itemsDivs(menuData.items, active, onClick, basicStyle, activeStyle) }
    </div>
  );
}

export const PageSectionMenuOrientation = {
  HORIZONTAL : 0,
  VERTICAL : 1
} as const;
export type PageSectionMenuOrientation = typeof PageSectionMenuOrientation[keyof typeof PageSectionMenuOrientation];

export default function PageSectionMenu (props: PageSectionMenuImplProps) {
  return <PageSectionMenuImpl {...props} />;
}

