import React, { useContext, ReactElement } from "react";

import { NewsItem } from "../libs/news-item";
import { LinkData, parseLocalDate } from "@chweb/commonlib";
import { latestNewsUrl } from "../libs/news-helpers";

import { InterractiveSection } from "@chweb/commonui";
import ColumnsSection from "../components/columns-section";
import NewsElement from "../components/one-news-section";
import WithMargins from "../components/with-margins";

import { localizeUrl } from "../libs/locale";
import { LocaleContext, getLocalizedValue } from "../components/locale-context";
import { ArticleInfo, getMediaSection } from "../queries/get-media-section";

const newsMarginValue = '50px';

function getNewsColumns (newsList: ArticleInfo[]) {
  const topNews = newsList.map(n => {
    const isMoreLink = n.excerpt.replace(/<(?:.|\n)*?>/gm, '').endsWith('…');
    return new NewsItem(
      n.slug,
      n.excerpt,
      parseLocalDate(n.date),
      n.source,
      isMoreLink
    );
  });

  let innerSection = <></>;

  if (topNews.length > 1) {
    // column one
    const newsOne = <WithMargins margin = { newsMarginValue }>
      <NewsElement content = { topNews[0] } />
    </WithMargins>;
      // column two
    const newsTwo = <WithMargins margin = { newsMarginValue }>
      <NewsElement content = { topNews[1] } />
    </WithMargins>;

    innerSection = <ColumnsSection
      cols = {[newsOne, newsTwo]}
    />;
  } else if (topNews.length > 0) {
    innerSection = <WithMargins margin={newsMarginValue}>
      <NewsElement content={topNews[0]} />
    </WithMargins>;
  }

  return innerSection;
}

// only news from this period will be considered
const MAX_NEWS_AGE_DAYS = 365;

function SectionMedia (): ReactElement | null {
  const { locale } = useContext(LocaleContext);
  const newsUrl = localizeUrl(latestNewsUrl(), locale);

  const mediaSectionData = getMediaSection(MAX_NEWS_AGE_DAYS);

  const localLabels = getLocalizedValue(mediaSectionData.labels, locale);
  const localNews = mediaSectionData.news.map(n => getLocalizedValue(n, locale));
  const linkData = new LinkData(newsUrl, localLabels.sectionMediaAllNews);
  return localNews.length ? (
    <InterractiveSection title = { localLabels.title } bottomLink = { linkData }>
      { getNewsColumns(localNews) }
    </InterractiveSection>
  ) : null;
}


export default SectionMedia;
