import { useStaticQuery, graphql } from 'gatsby';

import { extractLocale, localizeUrl } from '../libs/locale';

import { pageInfoByLocale } from '../queries/page-info-helpers';
import { getWineLocalTitle } from '../queries/page-wine-helpers';
import parseTOC, { LinkAndLabel } from '../libs/markdown-toc';

import { WineInfoData } from './fragment-wine-list-detailed';
import { WinePageData } from './fragment-wine-page';
import { PageInfoData } from './fragment-page-info';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export interface WinesSectionWine {
  id: string,
  title: string,
  icon: {
    image: IGatsbyImageData
  },
  bottleimage: {
    image: IGatsbyImageData
  },
  slug: string
}

interface WinesSectionData {
  intro: {
    title: string,
    html: string,
    menu: LinkAndLabel[]
  }
  wines: WinesSectionWine[]
}
type WinesSectionLocaleData = { [key: string]: WinesSectionData };

export default function getWinesSection (): WinesSectionLocaleData {
  const { wineInfo, winePage, page }: { wineInfo: WineInfoData, winePage: WinePageData, page: PageInfoData} = useStaticQuery(
    // $isPage variable below is used by winePageData fragment
    // see implementation
    graphql`
      query ($isPage: Boolean = false, $includeBottle: Boolean = true, $includeDescriptions: Boolean = false ) {
        wineInfo: markdownRemark(fields: {slug: {eq: "/wines-list/"}}) {
          ...wineInfoData
        }
        winePage: allMarkdownRemark(filter: {fields: {slug: {regex: "/wines\\.[a-z]{2}/"}}}) {
          ...winePageData
        }
        page: pageIndexYaml (yamlId: {eq: "wines"}) {
          ...PageInfo
        }
      }`
  );

  const { intros, wines } = wineInfo.frontmatter;

  const localPageInfo = pageInfoByLocale(page);

  const byLocale: WinesSectionLocaleData = {};
  
  intros.locales.forEach(l => {
    const sectionTitle = localPageInfo[l.locale].title;
    byLocale[l.locale] = {
      intro: {
        title: sectionTitle,
        html: l.file.childMarkdownRemark.html,
        menu: []
      },
      wines: []
    };
  });

  // common wine information
  Object.keys(byLocale).forEach(l => {
    byLocale[l].wines = wines.map(w => {
      return {
        id: w.id,
        icon: {
          image: w.icon.childImageSharp.gatsbyImageData
        },
        bottleimage: {
          image: w.bottleimage.childImageSharp.gatsbyImageData
        },
        slug: '',
        title: ''
      };
    });
  });

  // localized parts
  wines.forEach(w => {
    w.locales.forEach(l => {
      const localData = l.file.childMarkdownRemark.frontmatter;
      const localWine = byLocale[l.locale].wines.find(wine => wine.id === w.id) as WinesSectionWine;
      Object.assign(localWine, {
        title: getWineLocalTitle(localData.pageId.pageTitle, l.locale),
        slug: localizeUrl(`/wines${l.file.childMarkdownRemark.fields.slug}`)
      });
    });
  });

  // submenu
  winePage.nodes.forEach(p => {
    const locale = extractLocale(p.fields.slug) as string;
    Object.assign(byLocale[locale].intro, {
      menu: parseTOC(p.fields.slug, p.tableOfContents)
    });
  });

  return byLocale;
}
