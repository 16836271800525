import { useStaticQuery, graphql } from 'gatsby';

import moment from 'moment';
import { NEWS_PAGE_PREFIX } from '../libs/news-helpers';
import { parseLocalMoment } from "@chweb/commonlib";
import { Locales } from "@chweb/commonui";
import { extractLocale, localizeUrl } from '../libs/locale';
import { pageInfoByLocale } from '../queries/page-info-helpers';
import { AllLocalesLabels } from './fragment-all-locales-labels';
import { PageInfoData } from './fragment-page-info';
import { LocalizedObject } from '../components/locale-context';

interface ArticleSection {
  edges: {
    node: ArticleInfoData
  }[]
}

interface ArticleInfoData {
  frontmatter: {
    date: string,
    source: string,
    title: string
  },
  fields: {
    slug: string
  },
  excerpt: string
}

interface MediaSectionData {
  labels: {
    nodes: AllLocalesLabels[]
  },
  page: PageInfoData,
  top_en: ArticleSection,
  top_fr: ArticleSection,
  top_ru: ArticleSection,
  top_zh: ArticleSection
}

export interface ArticleInfo {
  date: string,
  source: string,
  excerpt: string,
  slug: string
}

export const getMediaSection = (limitToDaysCount?:number) => {
  const TOP_PREFIX = 'top_';
  const data: MediaSectionData = useStaticQuery(
    graphql`query (
        $en: String = ".+/media/[^/]*\\.en\\.md$/",
        $fr: String = ".+/media/[^/]*\\.fr\\.md$/",
        $ru: String = ".+/media/[^/]*\\.ru\\.md$/",
        $zh: String = ".+/media/[^/]*\\.zh\\.md$/",
        $limit: Int = 2)
    {
      labels: allLabelsTranslatedYaml(filter: {name: {in: [
          "sectionMediaAllNews"
      ]}}) {
        nodes {
          ...AllLocalesLabels
        }
      }
      page: pageIndexYaml (yamlId: {eq: "media"}) {
        ...PageInfo
      }
      top_en: allMarkdownRemark(filter: {
        fileAbsolutePath: {regex: $en}},
        sort: {fields: [frontmatter___date], order: DESC}, limit: $limit)
        {edges{node{...dataFragment}}}
      top_fr: allMarkdownRemark(filter: {
        fileAbsolutePath: {regex: $fr}},
        sort: {fields: [frontmatter___date], order: DESC}, limit: $limit)
        {edges{node{...dataFragment}}}
      top_ru: allMarkdownRemark(filter: {
        fileAbsolutePath: {regex: $ru}},
        sort: {fields: [frontmatter___date], order: DESC}, limit: $limit)
        {edges{node{...dataFragment}}}
      top_zh: allMarkdownRemark(filter: {
        fileAbsolutePath: {regex: $zh}},
        sort: {fields: [frontmatter___date], order: DESC}, limit: $limit)
        {edges{node{...dataFragment}}}
    }

    fragment dataFragment on MarkdownRemark {
      frontmatter {
        date
        source
        title
      }
      fields {
        slug
      }
      excerpt (pruneLength: 250, format: HTML)
    }
    `
  );

  const byLocale: LocalizedObject<{[key:string]: string}> = {};

  const ensureLocaleExists = (locale: string) => {
    if (!byLocale.hasOwnProperty(locale)) {
      byLocale[locale] = {};
    }
  };

  data.labels.nodes.forEach(l => {
    const label = l.name;
    const locales = l.locales;
    Object.keys(locales).forEach(locale => {
      ensureLocaleExists(locale);
      byLocale[locale][label] = locales[locale];
    });
  });

  // choosing news within MAX_NEWS_AGE_DAYS and flattening fetched items
  const cutOffDate = limitToDaysCount ? moment().add(-limitToDaysCount, 'days') : null;
  const topNews: ArticleInfoData[] = [];
  Object.keys(data).forEach(k => {
    const localeCandidate = k.replace(TOP_PREFIX, '');
    if (localeCandidate in Locales) {
      (data as unknown as { [key: string]: ArticleSection })[k].edges.forEach(n => {
        if (!cutOffDate) {
          topNews.push(n.node);
        }
        else {
          // only adding if after the cut off date
          const articleDate = parseLocalMoment(n.node.frontmatter.date);
          if (articleDate >= cutOffDate) {
            topNews.push(n.node);
          }
        }
      });
    }
  });

  // sorting by date then by source
  topNews.sort((a, b) => {
    if (a.frontmatter.date === b.frontmatter.date) {
      if (a.frontmatter.source === b.frontmatter.source) {
        return a.frontmatter.title < b.frontmatter.title ? -1
          : a.frontmatter.title > b.frontmatter.title ? 1 : 0;
      }
      return a.frontmatter.source < b.frontmatter.source ? -1
        : a.frontmatter.source > b.frontmatter.source ? 1 : 0;
    }
    return a.frontmatter.date < b.frontmatter.date ? 1
      : a.frontmatter.date > b.frontmatter.date ? -1 : 0;
  });

  // bucketing news by date + source + title(considering locale)
  const byDate: {[key: string]: ArticleInfoData}[] = [];
  topNews.forEach(n => {
    const locale = extractLocale(n.fields.slug) as string;

    if (byDate.length === 0) {
      const value: {[key: string]: ArticleInfoData} = {};
      value[locale] = n;
      byDate.push(value);
    } else {
      const lastKnown = byDate[byDate.length - 1];

      const lastKnownSample = lastKnown[Object.keys(lastKnown)[0]];
      if (
        // if date is different
        lastKnownSample.frontmatter.date > n.frontmatter.date || (
        // or if source is different
          lastKnownSample.frontmatter.date === n.frontmatter.date &&
          lastKnownSample.frontmatter.source !== n.frontmatter.source) || (
        // or if alrady seen this locale (means two news from same source on same date)
          lastKnownSample.frontmatter.date === n.frontmatter.date &&
          lastKnownSample.frontmatter.source === n.frontmatter.source &&
          Object.keys(lastKnown).indexOf(locale) !== -1)
      ) {
        const value: {[key: string]: ArticleInfoData} = {};
        value[locale] = n;
        byDate.push(value);
      } else {
        lastKnown[locale] = n;
      }
    }
  });

  // making sure we get only two
  if (byDate.length > 2) {
    byDate.length = 2;
  }

  // finally changing the object format
  const newsByDate = byDate.map(t => {
    const accByDate: { [key: string]: ArticleInfo } = {};
    Object.keys(t).reduce((acc, locale: string) => {
      const elem = t[locale];
      acc[locale] = {
        date: elem.frontmatter.date,
        source: elem.frontmatter.source,
        excerpt: elem.excerpt,
        slug: localizeUrl(`/${NEWS_PAGE_PREFIX}${elem.fields.slug}`)
      };
      return acc;
    }, accByDate)

    return accByDate;
  });

  const pageInfo = pageInfoByLocale(data.page);
  Object.keys(pageInfo).forEach(l => {
    byLocale[l]['title'] = pageInfo[l].title;
    byLocale[l]['titleTooltip'] = pageInfo[l].tooltip;
  });

  return {
    labels: byLocale,
    news: newsByDate 
  };
};
