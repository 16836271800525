import React, { useContext, ReactElement } from 'react';

import PageSection from '../components/page-section';
import ColumnsSection from '../components/columns-section';
import WineCard from '../components/wine-card';
import WithMargins from '../components/with-margins';
import HTMLDiv from '../components/htmldiv';

import {
  generate as generateMenu
} from '../libs/menu-data';

import { localizeUrl } from '../libs/locale';

import { LocaleContext, getLocalizedValue } from '../components/locale-context';
import getWinesSection, { WinesSectionWine } from '../queries/get-wines-section';

const contentDescription = (html: string): ReactElement => <HTMLDiv className={"w3-section"} __html={html} />;

function WinesSection () {
  const wineCards = (wines: WinesSectionWine[]) => wines.map((w,i) =>
    <WineCard
      key={i}
      img = { w.bottleimage.image }
      imgAlt = { w.title }
      link = { w.slug }
    />
  );

  const { locale } = useContext(LocaleContext);
  const winesSectionData = getWinesSection();

  const localWinesSection = getLocalizedValue(winesSectionData, locale);
  return (
    <PageSection
      titleLogoUrl = { localizeUrl('/wines/', locale) }
      titleLogoAlt = { localWinesSection.intro.title }
      menuData = { generateMenu(localWinesSection.intro.menu) }>
      { contentDescription(localWinesSection.intro.html) }
      <WithMargins margin = "10%">
        <ColumnsSection cols = { wineCards(localWinesSection.wines) } />
      </WithMargins>
    </PageSection>
  );
}

export default WinesSection;
