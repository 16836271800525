import { useStaticQuery, graphql } from 'gatsby';

import { localizeUrl, reduceLocales } from '../libs/locale';

import { parseLocalDate } from "@chweb/commonlib";
import { IGatsbyImageData } from 'gatsby-plugin-image';

function localizeAnnouncementUrl(url: string, locale: string, availableUrls: { path: string }[]): string {
  const localizedUrl = localizeUrl(url, locale);

  if (availableUrls.some(url => url.path === localizedUrl)) {
    return localizedUrl;
  }
  return url;
}

interface AnnouncementFrontmatter {
  startDate: string,
  endDate: string,
  url: string,
  background: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  },
  fancyFont: boolean,
  darkFont: boolean,
  message: {
    locale: string,
    text: string,
    title: string
  }
}

interface AnnouncementsData {
  announcements: {
    nodes: {
      frontmatter: AnnouncementFrontmatter
    }[]
  },
  availableUrls: {
    nodes: {
      path: string
    }[]
  }
}

export const getAnnouncements = (locale: string, dateOverride: Date | null = null) => {
  const { announcements, availableUrls }: AnnouncementsData = useStaticQuery(
    graphql`{
      announcements: allMarkdownRemark(
        filter: {fileAbsolutePath:{regex: ".+/announcements/[^\\.]*.md$/"}},
        sort: {fields: [frontmatter___startDate], order: ASC}
      )
      {
        nodes {
          frontmatter {
            startDate
            endDate
            url
            background {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1000, quality: 100)
              }
            }
            fancyFont
            darkFont
            message {
              locale
              text
              title
            }
          }
        }
      }
      availableUrls: allSitePage {
        nodes {
          path
        }
      }
    }`
  );

  const today = dateOverride instanceof Date ? dateOverride : new Date();
  const qualified = announcements.nodes.filter(a => {
    const startDate = parseLocalDate(a.frontmatter.startDate);
    const endDate = parseLocalDate(a.frontmatter.endDate);
    return startDate <= today && endDate >= today;
  });

  // only taking the first qualifying announcement
  if (qualified.length > 0) {
    const LAST = qualified.length - 1;
    const firstAnnouncement: AnnouncementFrontmatter = reduceLocales(qualified[LAST].frontmatter as any, locale);
    if (firstAnnouncement.url) {
      firstAnnouncement.url = localizeAnnouncementUrl(firstAnnouncement.url, locale, availableUrls.nodes);
    }
    return firstAnnouncement;
  }

  return null;
};
