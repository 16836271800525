import React, { ReactElement, useContext } from 'react';
import { GatsbyImage as Img, IGatsbyImageData } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import { LocaleContext, getLocalizedValue } from '../components/locale-context';

import WineMatches from '../components/wine-matches-icons';
import RecipeStats from '../components/recipe-stats';

import { getOneRecipeLabels } from '../queries/get-one-recipe-labels';

import {
  RecipeStats as RecipeStatsData
} from '../libs/recipe-stats';

interface RecipeExerptProps {
  text: string,
  recipeLink: string
}

function RecipeExerpt ({ text, recipeLink }: RecipeExerptProps): ReactElement {
  const labels = getOneRecipeLabels();
  const { locale } = useContext(LocaleContext);
  const localLabels = getLocalizedValue(labels, locale);

  return (
    <p>{text}<br />
      <Link className = "cpcolor-hover-brown cpcolor-text-brown"
        to = { recipeLink }>{ localLabels.sectionRecipesMoreLink }</Link>
    </p>
  );
}

export interface RecipeSectionProps {
  title: string,
  titleimage: IGatsbyImageData,
  recipeLink: string,
  excerpt: string,
  wineIdMatches: string[],
  content?: string,
  recipeStats: RecipeStatsData,
  verticalLayout?: boolean,
  rawText?: string
}

function RecipeSection ({ title, titleimage, recipeLink, excerpt, wineIdMatches, content, recipeStats, verticalLayout, rawText }: RecipeSectionProps): ReactElement {
  if (!wineIdMatches || !Array.isArray(wineIdMatches)) {
    wineIdMatches = [];
  }

  if (verticalLayout) {
    return (
        <>
          <div>
            <Link to = { recipeLink } title = { title }>
              <Img image={titleimage} style = {{ width: `100%` }} alt={title} />
            </Link>
          </div>
          <h2>
            <Link
              className = { (excerpt ? 'w3-xlarge' : 'w3-xxlarge').concat(' cpcolor-text-brown')}
              to = { recipeLink }
            >
              { title }
            </Link>
          </h2>
          <WineMatches wineIds = { wineIdMatches }/>
          { recipeStats &&  <RecipeStats stats = {recipeStats} />}
          { excerpt
            ? <RecipeExerpt text = { excerpt } recipeLink = { recipeLink } />
            : <div dangerouslySetInnerHTML = {{ __html: content || '' }}></div>
          }
        </>
    );
  } else {
    return (
        <>
          <h2>
            <Link
              className = { (excerpt ? 'w3-xlarge' : 'w3-xxlarge').concat(' cpcolor-text-brown')}
              to = { recipeLink }
            >
              { title }
            </Link>
          </h2>
          <div className = "w3-row-padding">
            <div className = "w3-third">
              <Link to = { recipeLink } title = { title }>
                <Img image = { titleimage } style = {{ width: '100%' }} alt = { title } />
              </Link>
            </div>
            <div className = "w3-twothird">
              <WineMatches wineIds = { wineIdMatches }/>
              { recipeStats &&  <RecipeStats stats = {recipeStats} />}
              { excerpt
                ? <RecipeExerpt text = { excerpt } recipeLink = { recipeLink } />
                : <div dangerouslySetInnerHTML = {{ __html: content || '' }}></div>
              }
            </div>
          </div>
        </>
    );
  }
}

export default RecipeSection;
