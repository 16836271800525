import React, { ReactElement, useEffect, useRef } from 'react';

type HTMLDivProps = {
  __html: string,
  [key: string]: string
};

export default function HTMLDiv ({ __html, ...other }: HTMLDivProps): ReactElement {
  const contentDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (contentDivRef.current) {
      contentDivRef.current.innerHTML = __html;
    }
  }, []);
  return (
    <div ref = { contentDivRef } dangerouslySetInnerHTML = {{ __html: __html }} {...other} />
  );
}
