import React from "react";
import { Link } from "gatsby";

import {
  InterviewQuestion as Question
} from "../libs/interview-question";

interface InterviewQuoteProps {
  by: string,
  quote: string
}

class InterviewQuote extends React.Component<InterviewQuoteProps> {
  render () {
    let optionalBy = null;
    if (this.props.hasOwnProperty('by')) {
      optionalBy = (<p>{this.props.by}</p>);
    }

    return (
      <div className ="w3-panel w3-leftbar cpcolor-border-brown">
        <i>&#10077;{this.props.quote}&#10078;</i>
        {optionalBy}
      </div>
    );
  }
}

interface InterviewQuestionProps {
  question: Question
}

class InterviewQuestion extends React.Component<InterviewQuestionProps> {
  render () {
    const question: Question = Object.assign({}, this.props.question);
    let optionalQuote = null;
    if (question.quote) {
      let quoteBy = '';
      if (question.quoteBy) {
        quoteBy = question.quoteBy;
      }
      optionalQuote = <InterviewQuote quote = { question.quote } by = {quoteBy} />;
    }

    let optionalLink = null;
    if (question.link) {
      const url = question.link.getLink();
      const internal = /^\/(?!\/)/.test(url);
      optionalLink = <div
        className ="w3-panel w3-border-left w3-margin cpcolor-border-brown"
      >
        {
          internal
            ? <Link
              className = "w3-button cpcolor-hover-brown"
              to = { question.link.getLink() }
            >
              { question.link.getLabel() }
            </Link>
            : <a
              className = "w3-button cpcolor-hover-brown"
              href= { question.link.getLink() }
            >
              { question.link.getLabel() }
            </a>
        }
      </div>;
    }

    return (
      <div>
        <p className = "w3-text-brown">{question.question}</p>
        <p className = "w3-large">{question.answer}</p>
        { optionalQuote }
        { optionalLink }
      </div>
    );
  }
}

export default InterviewQuestion;
