import { useStaticQuery, graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import { Locales } from "@chweb/commonui";
import { localizeUrl } from '../libs/locale';
import { pageInfoByLocale } from '../queries/page-info-helpers';
import { AllLocalesLabels } from './fragment-all-locales-labels';
import { PageInfoData } from './fragment-page-info';

export interface RecipePrepData {
  prepmins: number,
  mins: number,
  servings: number
}

interface RecipesSectionData {
  labels: {
    nodes: AllLocalesLabels[]
  },
  page: PageInfoData,
  top: {
    edges: {
      node: {
        frontmatter: {
          titleimage: {
            childImageSharp: {
              gatsbyImageData: IGatsbyImageData
            }
          },
          pairing: string[],
          info: RecipePrepData,
          locales: {
            locale: string,
            file: {
              childMarkdownRemark: {
                frontmatter: {
                  title: string
                },
                fields: {
                  slug: string
                },
                excerpt: string
              }
            }
          }[]
        }
      }
    }[]
  }
}

export interface RecipeData{
  title: string,
  titleimage: IGatsbyImageData,
  pairing: string[],
  info: RecipePrepData,
  excerpt: string,
  url: string 
}

export const getRecipesSection = () => {
  const { top, labels, page }: RecipesSectionData = useStaticQuery(
    graphql`query ($limit: Int = 6) {
      labels: allLabelsTranslatedYaml(
        filter: {name: {in: ["sectionRecipesAllLink"]}}) {
        nodes {
          ...AllLocalesLabels
        }
      }
      page: pageIndexYaml (yamlId: {eq: "recipes"}) {
        ...PageInfo
      }
      top: allMarkdownRemark(filter: {fileAbsolutePath: {regex: ".+/recipe-[^\\.]*.md$/"}}, sort: {fields: [frontmatter___popularity], order: DESC}, limit: $limit) {
        edges {
          node {
            frontmatter {
              titleimage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 400)
                }
              }
              pairing
              info {
                prepmins
                mins
                servings
              }
              locales {
                locale
                file {
                  childMarkdownRemark {
                    frontmatter {
                      title
                    }
                    fields {
                      slug
                    }
                    excerpt(pruneLength: 250)
                  }
                }
              }
            }
          }
        }
      }
    }`
  );

  const byLocale: { [key: string]: {[key: string]: string | RecipeData[]}} = {};

  const ensureLocaleExists = (locale: string) => {
    if (!(locale in byLocale)) {
      byLocale[locale] = {};
    }
  };

  labels.nodes.forEach(l => {
    const label = l.name;
    const locales = l.locales;
    Object.keys(locales).forEach(locale => {
      ensureLocaleExists(locale);
      byLocale[locale][label] = locales[locale];
    });
  });

  Object.keys(Locales).forEach(l => {
    ensureLocaleExists(l);

    byLocale[l].recipes = [] as RecipeData[];

    for (let i = 0, m = top.edges.length; i < m; ++i) {
      const r = top.edges[i];
      
      const recipeData = r.node.frontmatter;
      const localMatch = r.node.frontmatter.locales.find(v => v.locale === l);
      if (!localMatch) {
        continue;
      }

      const localData = localMatch.file.childMarkdownRemark;
      const recipe: RecipeData = {
        title: localData.frontmatter.title,
        titleimage: recipeData.titleimage.childImageSharp.gatsbyImageData,
        pairing: recipeData.pairing,
        info: recipeData.info,
        excerpt: localData.excerpt,
        url: localizeUrl(`/recipes${localData.fields.slug}`)
      };

      (byLocale[l].recipes as RecipeData[]).push(recipe);
    }
  });

  const pageInfo = pageInfoByLocale(page);
  Object.keys(pageInfo).forEach(l => {
    byLocale[l].title = pageInfo[l].title;
    byLocale[l].titleTooltip = pageInfo[l].tooltip;
  });

  return byLocale;
}
