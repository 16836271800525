import { LinkAndLabel } from "./markdown-toc";

class MenuItem {
  private text: string;
  private tooltip: string | null;
  private link: string | null;

  constructor (text: string, tooltip: string | null = null, link: string | null = null) {
    this.text = text;
    this.tooltip = tooltip;
    this.link = link;
  }

  public getText(): string  {
    return this.text || '';
  }

  public getTooltip (): string {
    return this.tooltip || '';
  }

  public isLink (): boolean {
    return this.link !== null;
  }

  public getLink (): string {
    return this.link || '';
  }
}

class MenuData {
  private _items: MenuItem[];

  constructor (items: MenuItem[] = []) {
    this._items = items;
  }

  get items(): MenuItem[] {
    return this._items;
  }

  public addItem (item: MenuItem): void {
    this._items.push(item);
  }
}

function generate (tableOfContents: LinkAndLabel[]): MenuData {
  const menu = new MenuData();
  if (Array.isArray(tableOfContents)) {
    tableOfContents.forEach(item => menu.addItem(
      new MenuItem(item.label, item.label, item.link)));
  }
  return menu;
}

export { MenuData, MenuItem, generate };
