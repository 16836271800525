import React, { useContext, useState, useEffect, ReactElement } from 'react';

import HorizontalBanner from './horizontal-banner';
import { getAnnouncements } from '../queries/get-announcements';
import { LocaleContext } from '../components/locale-context';

import { PageQueryParams } from '../libs/url';

export default function Announcement (): ReactElement {
  const params = new PageQueryParams();

  const { locale } = useContext(LocaleContext);

  const [client, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  let date = new Date();
  if (params.isDateOverride()) {
    date = params.getDateOverride(date);
  }

  // we need to call this anyway to make sure React is happy
  // othewise the number of hooks called by React is defferent between
  // the re-renders and it might screw things up
  let ann = getAnnouncements(locale, date);
  if (!ann)
    return <></>;

  // making sure this is not rendered during SSR
  ann = client ? ann : null;
  return <div>
    {
      ann
        ? <HorizontalBanner
          url = { ann.url }
          message = { ann.message ? ann.message.text : undefined }
          title = { ann.message ? ann.message.title : undefined }
          image = { ann.background.childImageSharp.gatsbyImageData }
          fancyFont = { ann.fancyFont }
          darkFont = { ann.darkFont }
        />
        : null
    }
  </div>;
}
