import React, { useContext, ReactElement } from 'react';

import PageSection from '../components/page-section';
import ColumnsSection from '../components/columns-section';
import ImageTitled from '../components/image-titled';
import HTMLDiv from '../components/htmldiv';

import { localizeUrl } from '../libs/locale';

import {
  LocaleContext,
  getLocalizedValue
} from '../components/locale-context';

import {
  DomaineIntroSlide,
  getDomaineIntro
} from '../queries/get-domaine-intro';

import {
  generate as generateMenu
} from '../libs/menu-data';

const contentImage = (slides: DomaineIntroSlide[]): ReactElement => {
  const slide = slides[0];
  return (
    <ImageTitled image={slide.image} description = {slide.description} />
  );
};

function pageSummary (htmlContent: string): ReactElement {
  return <HTMLDiv __html = { htmlContent } />;
}

function SectionDomaine () {
  const domaineData = getDomaineIntro();
  const {
    locale
  } = useContext(LocaleContext);
  const localData = getLocalizedValue(domaineData, locale);
  return (
    <PageSection titleLogoUrl={localizeUrl('/domaine/', locale)}
      titleLogoAlt={localData.intro ? localData.intro.title : ''}
      menuData = { generateMenu(localData.menu || []) }>
    < ColumnsSection cols = {
      [
        pageSummary(localData.intro ? localData.intro.text : ''),
        contentImage(localData.intro ? localData.intro.slides: [])
      ]
    }
      />
    </PageSection >
  );
}

export default SectionDomaine;
