import React, { PropsWithChildren, ReactElement } from 'react';

import PageSectionMenu from '../components/page-section-menu';
import { Link } from 'gatsby'

import { MenuData } from '../libs/menu-data';

type PageSectionProps = PropsWithChildren<{
  titleLogoUrl: string,
  titleLogoAlt: string,
  menuData: MenuData,
  fancyTitle?: boolean 
}>;

export default function PageSection ({ titleLogoUrl, titleLogoAlt, menuData, children, fancyTitle }: PageSectionProps): ReactElement {
  fancyTitle = fancyTitle === undefined ? true : fancyTitle;
  let logo: ReactElement | null = null;
  if (titleLogoAlt) {
    logo = fancyTitle
      ? <span className = "cptitle cpcolor-text-brown">{ titleLogoAlt }</span>
      : <h2 className = "cpcolor-text-brown">{ titleLogoAlt }</h2>;
  }

  if (titleLogoUrl) {
    logo = <Link to = { titleLogoUrl }>{ logo }</Link>;
  }
  return (
    <section className = "w3-container w3-white w3-padding-16 w3-margin-top" >
      <header>
        { logo }
        <PageSectionMenu menuData={menuData} active={0} />
      </header>
      { children }
    </section>
  );
}
