import React, { useContext } from 'react';
import { Tiles } from '../components/tiles';

import { LocaleContext } from '../components/locale-context';
import { getExtraLinkTiles } from '../queries/get-extra-link-tiles';

export default function ExtraContentLinks (): React.ReactElement {
  const { locale } = useContext(LocaleContext);

  const tiles = getExtraLinkTiles(locale);

  return (
    <div className = "w3-padding-24">
      <Tiles tiles = { tiles } locale = { locale } height = { '125px' } />
    </div>
  );
}
