import React, { ReactElement } from 'react';
import { GatsbyImage as Img, IGatsbyImageData } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import {
  notFancy as cssNotFancy,
  fancy as cssFancy
} from './horizontal-banner.module.css';

interface HorizontalBannerProps {
  message?: string,
  title?: string,
  image: IGatsbyImageData,
  url: string,
  fancyFont: boolean,
  darkFont: boolean
}

export default function HorizontalBanner ({ message, title, image, url, fancyFont, darkFont }: HorizontalBannerProps): ReactElement {
  const baseClass = 'w3-panel w3-margin w3-padding w3-opacity-min';
  const fontColor = darkFont ? 'cpcolor-text-brown' : 'w3-text-white';
  const notFancyClass = `${baseClass} w3-white w3-text-black w3-leftbar ${cssNotFancy}`;
  const fancyClass = `${baseClass} ${fontColor} ${cssFancy}`;

  const content = (
    <div className = "w3-padding-64" style = {{ position: 'relative' }}>
      <Img
        image = { image }
        className = "w3-image"
        style = {{ width: `1000px` }}
        alt = {title || ""}
      />
      { message
        ? <div className = { fancyFont ? fancyClass : notFancyClass }>
          { !fancyFont && title ? <h4>{ title }</h4> : null }
          <p className = "w3-panel">{ message }</p>
        </div>
        : null }
    </div>
  );

  return url ? <Link to = {url}>{ content }</Link> : content;
}
